import $ from 'jquery'

$('#detail-slider').slick({
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
  nextArrow: '.detail--right',
  prevArrow: '.detail--left',
})
$('#product-slider').slick({
  dots: true,
  dotsClass: 'slick-dots flex justify-center absolute bottom-0 left-0 right-0 p-6',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
  nextArrow: '#product--right',
  prevArrow: '#product--left',

  responsive: [
    {
      breakpoint: 9999,
      settings: {
        dots: false,
      },
    },
    {
      breakpoint: 640,
      settings: {
        dotsClass: 'slick-dots flex justify-center absolute bottom-0 left-0 right-0 p-6',
      },
    },
  ],
})
