import $ from 'jquery'
import 'slick-carousel'

import './3d-view'
import './benefit-slider'
import './detail-full-slider'
import './detail-slider'
import './form'
import './menu.js'
import './reveal-more.js'
import './scroll-reveal.js'
import './search'

window.$ = window.jQuery = $
