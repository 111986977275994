import $ from 'jquery'
import SimpleLightbox from 'simple-lightbox'

$('#detail-recommendation').slick({
  dots: true,
  dotsClass: 'column__wrap slick-dots',
  infinite: false,
  variableWidth: false,
  speed: 300,
  arrows: false,

  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick',
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.5,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
  ],
})

new SimpleLightbox({
  elements: '#draw',
})
