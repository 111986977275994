const d = document

const form = d.getElementById('search')
const show = d.getElementById('show-search')
const showMobile = d.getElementById('show-search-mobile')
const close = d.getElementById('search-close')
const input = d.getElementById('search-query')
const whisperer = d.getElementById('search-whisperer')
const body = d.body

function toggleSearch() {
  d.body.classList.toggle('overflow-hidden')
  d.getElementById('search').classList.toggle('hidden')
}

show && show.addEventListener('click', toggleSearch)
showMobile && showMobile.addEventListener('click', toggleSearch)
close && close.addEventListener('click', toggleSearch)

function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

function clearElement(element) {
  while (element.firstChild) {
    element.removeChild(element.lastChild)
  }
}

const EMPTY = []
let lastList = EMPTY
function renderWhisperer(list = EMPTY) {
  if (list === lastList) return
  lastList = list
  clearElement(whisperer)
  const fragment = d.createDocumentFragment()
  list.forEach(item => {
    const li = d.createElement('li')
    const a = d.createElement('a')
    a.href = item.url
    a.textContent = item.prod_name
    a.className = 'hover:text-primary'
    li.appendChild(a)
    fragment.appendChild(li)
  })

  whisperer.appendChild(fragment)
}

let lastSearch = ''
function whisper(event) {
  const search = event.target.value
  if (search.length <= 2) return renderWhisperer()
  if (search === lastSearch) return
  lastSearch = search
  console.log(search)
  const queryString = new URLSearchParams({ search })
  fetch(`${form.action}/whisperer?${queryString}`).then(response => {
    if (!response.ok) {
      return renderWhisperer()
    }
    response.json().then(list => {
      // to only get fresh data
      if (search === lastSearch) {
        renderWhisperer(list)
      }
    })
  })
}

const whisperDebounced = debounce(whisper, 50)

input.addEventListener('input', whisperDebounced)
