import $ from 'jquery'

$('#benefit-slider').slick({
  dots: true,
  dotsClass: 'slick-dots mt-4',
  infinite: false,
  variableWidth: false,
  speed: 300,
  arrows: false,

  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick',
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
  ],
})
$('#menu-slider').slick({
  dots: true,
  infinite: false,
  variableWidth: false,
  speed: 300,
  arrows: false,

  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick',
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
  ],
})
