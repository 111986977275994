const options = {
  rootMargin: '0%',
  threshold: 0.3,
  selector: '.show-on-scroll',
  animateClassName: 'shown',
}

let elements = []
let intersectionObserver = null

function animate(entry) {
  entry.target.classList.add(options.animateClassName)
}

function isNotAnimated(element) {
  return !element.classList.contains(options.animateClassName)
}

function onIntersection(entries, observer) {
  entries.forEach(function (entry) {
    if (entry.intersectionRatio >= options.threshold && entry.isIntersecting) {
      animate(entry)
      observer.unobserve(entry.target)
    }
  })
}

function setup() {
  elements = [].filter.call(document.querySelectorAll(options.selector), isNotAnimated)

  elements.forEach(function (element) {
    intersectionObserver.observe(element)
  })
}

function enable() {
  intersectionObserver = new IntersectionObserver(onIntersection, {
    rootMargin: options.rootMargin,
    threshold: options.threshold,
  })
}

if (window.IntersectionObserver) {
  enable()
  setup()

  window.scrollReveal = setup
} else {
  window.scrollReveal = function () {}
}
